import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import getLogger, { LogGroup } from "js/core/logger";
import db from "js/db";
import { app } from "js/namespaces";
import { User } from "js/core/models/user";
import { ds } from "js/core/models/dataService";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import NotificationsService from "js/core/services/notifications";
import ErrorDialog from "js/react/components/Dialogs/ErrorDialog";
import theme from "js/react/materialThemeOverrides";

import { LoadingPage } from "js/react/components/LoadingPage";
import ProvideAccessDialog from "js/react/views/ProvideAccessDialog/ProvideAccessDialog";
import * as analytics from "js/analytics";

const logger = getLogger(LogGroup.PERMISSIONS);

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: #F8FAFC;
`;

const ErrorDialogMessage = styled.p`
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;     
    letter-spacing: 0.5px;
`;

const ErrorDialogInfo = styled.p`
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
`;

class ProvideAccessPage extends React.Component {
    static propTypes = {
        firebaseUser: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    constructor() {
        super();

        this.state = {
            fetching: true,
            notification: null,
            error: null
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { firebaseUser, match: { params: { notificationId } } } = this.props;

        try {
            // Initialization (similar to the steps we perform before rendering main view)
            await db.updateCurrentUser(firebaseUser);
            app.user = new User({ id: firebaseUser.uid }, { autoLoad: false });
            await ds.setup();
            await app.user.load();
            await analytics.identify(app.user.getAuthUser());

            // Loading notification
            const notification = await NotificationsService.getNotification(firebaseUser.uid, notificationId);

            this.setState({ notification, fetching: false });
        } catch (err) {
            logger.error(err, "ProvideAccessPage load() failed", { uid: firebaseUser?.uid, notificationId });

            this.setState({
                error: {
                    message: "Sorry! We weren’t able to find this access request.",
                    info: "Either the link is wrong, or the request has been deleted."
                },
                fetching: false
            });
        }
    }

    render() {
        const { fetching, error, notification } = this.state;

        if (fetching) {
            return <LoadingPage />;
        }

        return (<Container>
            <MuiThemeProvider theme={theme}>
                {error && <ErrorDialog
                    message={(<>
                        <ErrorDialogMessage>{error.message}</ErrorDialogMessage>
                        <ErrorDialogInfo>{error.info}</ErrorDialogInfo>
                    </>)}
                    preventClose
                    hideBackdrop
                    closeDialog={() => window.location = "/"}
                />}
                {notification && <ProvideAccessDialog
                    notification={notification}
                    closeDialog={() => window.location = "/"}
                />}
            </MuiThemeProvider>
        </Container>);
    }
}

export default withRouter(withFirebaseUser(ProvideAccessPage));
